import { I18n } from 'i18n';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { Table as TableUI, Tag, Select, Input } from 'antd';
import { IDataPoints } from 'types';
import { GetDataPoints } from 'apollo/data-points';
import { useEffect, useState } from 'react';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const { Column } = TableUI;

const { Option } = Select;

interface NameProps {
  onShowDrawer: (_id?: string) => void;
}

export function ListDataPoints({ onShowDrawer }: NameProps) {
  const history = useHistory();
  const { data: dataDataPoints, loading: isLoading, error } = GetDataPoints();
  const [searchDataPoint, setSearcDataPoint] = useState<string>('');
  const [transformData, setTransformData] = useState<IDataPoints[]>([]);
  const [filteredCustomer, setFilteredCustomer] = useState<string | null>(null);
  const [status, setStatus] = useState('active');

  const uniqueNames = Array.from(
    new Set(transformData.map((datapoint: any) => datapoint.customer.name)),
  );

  if (error) history.push('/error-server');

  useEffect(() => {
    let result: IDataPoints[] = [];
    dataDataPoints.forEach((rows: any) => {
      if (!rows.name.toLowerCase().includes(searchDataPoint.toLocaleLowerCase())) return;
      result.push(rows);
    });
    setTransformData(result);
  }, [dataDataPoints, searchDataPoint, isLoading]);

  useEffect(() => {
    const filteredData = dataDataPoints
      .filter(
        (dataPoint: IDataPoints) =>
          !filteredCustomer || dataPoint.customer.name === filteredCustomer,
      )
      .filter((dataPoint: IDataPoints) =>
        status === 'active' ? dataPoint.active === true : dataPoint.active === false,
      );

    setTransformData(filteredData);
  }, [filteredCustomer, dataDataPoints, status]);

  const hasFiltersApplied = filteredCustomer !== null || status !== 'active';

  const handleStatusChange = (value: any) => {
    setStatus(value);
  };

  return (
    <>
      <div className="container" style={{ padding: 0, marginBottom: 12, display: 'flex' }}>
        <div style={{ width: '50%' }}>
          <Select
            placeholder={I18n('filter_by_customer', true)}
            style={{ width: 160, marginRight: 6 }}
            value={filteredCustomer || null}
            onChange={(value) => {
              setFilteredCustomer(value);
            }}
          >
            {uniqueNames.map((name) => (
              <Option key={name} value={name}>
                {name}
              </Option>
            ))}
          </Select>
          <Select
            value={status}
            onChange={handleStatusChange}
            style={{ width: 120, marginRight: 6 }}
          >
            <Option value="active">{I18n('active', true)}</Option>
            <Option value="inactive">{I18n('inactive', true)}</Option>
          </Select>
          {hasFiltersApplied && (
            <button
              style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}
              onClick={() => {
                setFilteredCustomer(null);
                setStatus('active');
                setTransformData(dataDataPoints);
              }}
            >
              <FontAwesomeIcon icon={faClose} /> {I18n('clear_filter', true)}
            </button>
          )}
        </div>
        <div style={{ width: '50%', display: 'flex' }}>
          <Input
            disabled={dataDataPoints.length > 10 ? false : true}
            onChange={(e) => setSearcDataPoint(e.target.value)}
            placeholder={I18n('find_data_point')}
            size="large"
            allowClear
            style={{ width: '70%', padding: 0, marginLeft: 'auto' }}
          />
        </div>
      </div>
      <TableUI
        dataSource={transformData}
        pagination={{ pageSize: 10, showSizeChanger: false }}
        loading={isLoading}
        bordered={true}
        rowKey={(data) => data._id}
        onRow={(data: IDataPoints, rowIndex) => {
          return {
            onClick: () => onShowDrawer(data?._id),
          };
        }}
      >
        {['name', 'longitude', 'latitude'].map((field) => (
          <Column title={I18n(field, true)} dataIndex={field} key={field} />
        ))}
        <Column
          title={I18n('customer', true)}
          dataIndex={'customer'}
          key={I18n('customer', true)}
          render={(key) => key.name}
        />
        <Column
          title={I18n('modules', true)}
          dataIndex={'module'}
          key={I18n('module', true)}
          render={(key) => (key ? I18n(key, true) : null)}
        />
        <Column
          title={I18n('location', true)}
          dataIndex={'location'}
          key={I18n('location', true)}
          render={(key) => (key ? I18n(key, true) : I18n('no_location'))}
        />
        <Column
          title={I18n('state', true)}
          dataIndex={'active'}
          key={'active'}
          render={(value) => {
            return (
              <Tag className="rk-tag" color={value ? 'green' : 'red'} key={value}>
                {value ? I18n('active', true) : I18n('inactive', true)}
              </Tag>
            );
          }}
        />
        <Column
          key="action"
          render={() => [
            <span style={{ cursor: 'pointer' }}>
              <FontAwesomeIcon icon={faEllipsisH} />
            </span>,
          ]}
        />
      </TableUI>
    </>
  );
}
