import { I18n } from 'i18n';
import { Alert, Table as TableUI, Tag, Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { GetIntegrator } from 'apollo/integrator';
import { Integrator } from 'types';
import { Dropdown } from 'components';
import { useState, useEffect } from 'react';

const { Column } = TableUI;

interface NameProps {
  onShowDrawer: (_id?: string) => void;
}

export function ListIntegrator({ onShowDrawer }: NameProps) {
  const [filteredStatus, setFilteredStatus] = useState<boolean[] | null>([true]);
  const [searchIntegrator, setSearchIntegrator] = useState<string>('');
  const [transformData, setTransformData] = useState<Integrator[]>([]);
  const { data: dataIntegrator, loading: isLoading, error } = GetIntegrator();
  useEffect(() => {
    let result: Integrator[] = [];
    dataIntegrator?.forEach((rows: any) => {
      if (!rows.name.toLowerCase().includes(searchIntegrator.toLocaleLowerCase())) return;
      result.push(rows);
    });
    setTransformData(result);
  }, [dataIntegrator, searchIntegrator, isLoading]);
  if (error && dataIntegrator)
    return <Alert message={I18n('internal_server_error')} type="error" closable />;

  return (
    <>
      <div
        className="container-integrator"
        style={{ padding: 0, marginBottom: 12, display: 'flex' }}
      >
        <div style={{ width: '50%' }}></div>
        <div style={{ width: '50%', display: 'flex' }}>
          <Input
            disabled={dataIntegrator?.length > 10 ? false : true}
            onChange={(e) => setSearchIntegrator(e.target.value)}
            placeholder={I18n('find_integrator')}
            size="large"
            allowClear
            style={{ width: '60%', padding: 0, marginLeft: 'auto' }}
          />
        </div>
      </div>
      <TableUI
        dataSource={transformData}
        pagination={{ pageSize: 10, showSizeChanger: false }}
        loading={isLoading}
        bordered={true}
        rowKey={(data: Integrator, i) => data?.name + i}
        onRow={(data: Integrator, rowIndex) => {
          return {
            onClick: () => onShowDrawer(data._id),
          };
        }}
        onChange={(pagination, filters) => {
          const statusFilters = filters.active as boolean[] | null;
          setFilteredStatus(statusFilters && statusFilters.length ? statusFilters : null);
        }}
      >
        <Column title={I18n('name', true)} dataIndex={'name'} key={'name'} />
        <Column title={I18n('api_key', true)} dataIndex={'apiKey'} key={'apiKey'} width={300} />
        <Column
          title={I18n('modules', true)}
          dataIndex={'modulesNames'}
          key={'modulesNames'}
          width={300}
          render={(modules) => (
            <div>
              {I18n(modules[0], true)}{' '}
              <Dropdown
                data={modules.map((r: string, i: number) => ({ key: i, label: I18n(r, true) }))}
                text={modules.length >= 2 ? `+${modules.length}` : ''}
              />
            </div>
          )}
        />
        <Column title={I18n('description', true)} dataIndex={'description'} key={'description'} />
        <Column
          title={I18n('state', true)}
          dataIndex={'active'}
          key={'active'}
          filteredValue={filteredStatus || null}
          filters={[
            { text: 'Activo', value: true },
            { text: 'Inactivo', value: false },
          ]}
          onFilter={(value, record: any) => record.active === value}
          render={(value) => {
            return (
              <>
                {value && value === true ? (
                  <Tag
                    className="rk-tag"
                    icon={<FontAwesomeIcon icon={faCheck} />}
                    color={'success'}
                  >
                    {`  ${I18n('active', true)}`}
                  </Tag>
                ) : (
                  <Tag className="rk-tag" icon={<FontAwesomeIcon icon={faTimes} />} color={'error'}>
                    {`  ${I18n('inactive', true)}`}
                  </Tag>
                )}
              </>
            );
          }}
        />
      </TableUI>
    </>
  );
}
